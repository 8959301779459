import React from 'react'
import { graphql } from 'gatsby'
import Seo from "src/components/seo";
import Layout from "src/components/layout/Layout";
import ContactFooter from "src/components/layout/ContactFooter/ContactFooter";
import Patrons from "src/components/layout/Patrons/Patrons";
import PostDetails from 'src/components/pages/post/PostDetails';

export const query = graphql`
  query Post($urlPath: String!) {
    strapiPost(urlPath: {eq: $urlPath}) {
    author
    content
    header
    urlPath
    headerImg {
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    title
    updatedAt
    minToRead
  }
  }
`
const Post = ({ data }) => {
  return (
    <Layout>
      <Seo />
      <PostDetails post={data.strapiPost} />
      <ContactFooter showForm={true} showInfo={false} />
      <Patrons />
    </Layout>
  )
}
export default Post;
import React from "react";
import ReactMarkdown from "react-markdown";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./PostDetails.module.scss";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const PostDetails = ({ post }: any) => {
  const postImage = getImage(post.headerImg.localFile.childImageSharp);

  return (
    <section className={styles.PostDetails}>
      <header className={styles.Header}>
        {postImage ? (
          <div>
            <GatsbyImage
              image={postImage}
              alt={`article picture`}
              style={{ width: "100%" }}
            />
          </div>
        ) : (
          <StaticImage
            src="../../../images/BlogHeader.png"
            alt="mountain view"
          />
        )}

        <div className={styles.TitleWrapper}>
          <h1>{post.title}</h1>
          <p>{`by ${post.author}`}</p>
        </div>
      </header>

      <article className={styles.ArticleWrapper}>
        <div className={styles.ContentWrapper}>
          <ReactMarkdown>{post.content}</ReactMarkdown>
        </div>
      </article>
    </section>
  );
};

export default PostDetails;
